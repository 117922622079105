<template>
  <div class="container">
    <transition name="fade">
      <div v-if="showGrid" class="tiles">
        <undo-tiles :mobile-with="mobileWith"></undo-tiles>
      </div>
    </transition>

    <transition name="back-line">
      <div v-if="showLine" class="white-line"></div>
    </transition>
    <h1 :class="showText ? 'title-white' : 'title-black'">{{ title }}</h1>
    <!--    <h1 v-if="showText">{{ title }}</h1>-->

    <transition name="have">
      <span v-show="showText" class="big-have">have</span>
    </transition>

    <transition name="slide">
      <h2 v-show="showText">{{ subTitle }}</h2>
    </transition>

    <div class="round-button">
      <transition name="fade">
        <round-button
          v-show="showText"
          :scroll-to="scrollToPromise"
        ></round-button>
      </transition>
    </div>
    <div class="container__available">
      <span style="display: block">freelance developer </span>
      <available-comp />
    </div>
  </div>
  <div id="continue-to-promise"></div>
</template>

<script>
import AvailableComp from "@/components/ui/availableComp";
import UndoTiles from "@/components/ui/undoTiles";
import RoundButton from "@/components/ui/roundButton";

export default {
  name: "heroSection",
  components: { RoundButton, UndoTiles, AvailableComp },
  props: [
    "heroInView",
    "scrollToPromise",
    "innerHeight",
    "mobileWith",
    "innerWidth",
  ],
  data() {
    return {
      title: "have a site!",
      subTitle: "sleek websites from design to realisation",
      showText: false,
      showLine: false,
      showGrid: false,
      intersectionObserver: null,
      continueIntersection: null,
    };
  },
  watch: {
    continueIntersection() {
      if (window.innerWidth < 500) {
        this.intersectionObserver.unobserve(
          document.querySelector("#continue-to-promise")
        );
        return;
      }
      if (this.showText) {
        this.scrollToPromise();
        this.intersectionObserver.unobserve(
          document.querySelector("#continue-to-promise")
        );
      }
    },
    showText() {
      if (this.continueIntersection) {
        this.scrollToPromise();
        this.intersectionObserver.unobserve(
          document.querySelector("#continue-to-promise")
        );
      }
    },
  },
  methods: {
    onElementObserved(entries) {
      if (entries[0].isIntersecting) {
        this.continueIntersection = true;
      }
    },
  },
  beforeUnmount() {
    this.intersectionObserver.unobserve(
      document.querySelector("#continue-to-promise")
    );
  },
  mounted() {
    this.showLine = true;
    this.intersectionObserver = new IntersectionObserver(
      this.onElementObserved,
      {
        threshold: 0.6,
      }
    );
    this.intersectionObserver.observe(
      document.querySelector("#continue-to-promise")
    );
    setTimeout(() => {
      this.showText = true;
      this.showLine = false;
      this.showGrid = true;
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
#continue-to-promise {
  width: 100%;
  height: 1rem;
  background-color: var(--background-primary-dark);
}

.tiles {
  position: absolute;
  top: 0;
  left: 0;
}

.round-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2vh;
  @media screen and (min-width: 40em) {
    bottom: 2vh;
  }
  button {
    &:hover {
      transform: scale(1.05);
      transition: 0.2s ease;
    }
  }
}

.title-black {
  color: var(--background-primary-dark);
}

.title-white {
  color: white;
  text-shadow: 3px 5px 8px rgba(0, 0, 0, 0.4);
  transition: all 2s ease-out;
}

.container {
  overflow: hidden;
  position: relative;
  width: 100vw;
  //background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), no-repeat top url("../../assets/images/IMG_4390.gif");
  background-size: cover;
  background-color: var(--background-primary-dark);
  color: var(--color-my-white);
  height: 100vh;

  @media screen and (min-width: 40em) {
  }

  &__available {
    font-family: ariana-pro,sans-serif;
    font-weight: 800;

    font-style: inherit;
    position: absolute;
    color: white;
    bottom: 4rem;
    left: 1rem;
    opacity: 0.8;
    text-shadow: 3px 5px 8px rgba(0, 0, 0, 0.4);

    @media screen and (min-width: 40em) {
      bottom: 12rem;
      font-size: 1.7rem;
      left: 3rem;
    }
    @media screen and (min-width: 90em) {
      font-size: 2rem;
    }
  }
}

.white-line {
  position: absolute;
  width: 100%;
  background-color: var(--color-my-white);
  top: 20vh;
  height: 12rem;

  @media screen and (min-width: 40em) {
    height: 16rem;
    top: 23vh;
  }
  @media screen and (min-width: 90em) {
    top: 20vh;
    height: 20rem;
  }
}

h1 {
  font-family: "Futura PT", sans-serif;
  position: relative;
  padding: 0 2rem;
  font-size: 6rem;
  top: 18vh;
  font-weight: 500;

  @media screen and (min-width: 40em) {
    padding: 0 6rem;
    font-size: 16rem;
    top: 0;
  }
  @media screen and (min-width: 90em) {
    font-size: 20rem;
  }
}

h2 {
  font-family: reross-quadratic,sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  text-align: end;
  color: var(--color-my-white);
  margin-right: 4rem;
  bottom: 22vh;
  right: 0;
  z-index: 2;
  text-shadow: 3px 5px 8px rgba(0, 0, 0, 0.4);
  font-size: 1.6rem;

  @media screen and (min-width: 40em) {
    bottom: 12vh;
    font-size: 3.6rem;

  }
  @media screen and (min-width: 90em) {
    bottom: 12vh;
    font-size: 7rem;
  }
}

.slide-enter-from {
  transform: translateX(400px);
  opacity: 0.2;
}

.slide-enter-active {
  transition: all 3s ease-out;
}

.back-line-enter-from {
  width: 0;
}

.back-line-enter-active,
.back-line-leave-active {
  transition: all 1.6s ease-out 1s;
}

.back-line-leave-to {
  height: 0;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 2s linear 4s;
}

.big-have {
  overflow: hidden;
  font-size: 32rem;
  font-family: "Futura PT", Lato, Avenir,Helvetica, Arial, sans-serif;
  position: absolute;
  top: 15vh;
  right: -55%;
  transform: rotate(90deg);
  color: white;
  opacity: 0.55;
  @media screen and (min-width: 40em) {
    font-size: 32rem;
    top: 20vh;
    right: -14vw;
  }
  @media screen and (min-width: 90em) {
    top: 20rem;
    font-size: 50rem;
  }
}

.have-enter-from {
  opacity: 0;
  transform: translateX(400px);
}

.have-enter-active {
  transition: all 2s ease 2s;
}

.button {
  position: absolute;
  right: 2rem;
  top: 80vh;
  @media screen and (min-width: 24em) {
    top: 80vh;
    right: 5rem;
  }

  @media screen and (min-width: 40em) {
    display: none;
  }
}
#continue-to-promise {
  border: dashed 1px var(--color-tertiary);
  height: 0.1rem;
}
</style>

<template>
  <div class="container" v-cloak>
    <input
      type="checkbox"
      class="container__check"
      v-model="hamburgerChecked"
    />
    <div class="container__menu_btn">
      <span
        class="container__menu_btn__burger"
        :class="{ container__menu_btn__burger_open: hamburgerChecked }"
      >
      </span>
    </div>
    <div>
      <transition name="collapse">
        <ul v-show="hamburgerChecked">

          <li v-on:click="hamburgerCheckedOff">
            <router-link
              :style="
                whiteBackground ? { color: 'black', textShadow: 'none' } : null
              "
              to="/"
              >home</router-link
            >
          </li>
<!--          <li v-on:click="hamburgerCheckedOff">-->
<!--            <router-link-->
<!--              :style="-->
<!--                whiteBackground ? { color: 'black', textShadow: 'none' } : null-->
<!--              "-->
<!--              to="/projects"-->
<!--              >portfolio</router-link-->
<!--            >-->
<!--          </li>-->
          <li   :style="
                whiteBackground ? { color: 'black', textShadow: 'none' } : null
              ">
            <router-link
              to="/contact-me"
              >contact me</router-link
            >
          </li>

          <li v-on:click="hamburgerCheckedOff">
            <a
              :style="
                whiteBackground ? { color: 'black', textShadow: 'none' } : null
              "
              href="mailto:victor.hodobay@gmail.com"
              >email</a
            >
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbar",
  props: ["whiteBackground"],
  data() {
    return {
      hamburgerChecked: false,
    };
  },
  methods: {
    hamburgerCheckedOff() {
      this.hamburgerChecked = false;
    },
    scrollToIntro() {
      this.hamburgerCheckedOff();
      this.$emit("scrollToIntro");
    },
  },
  emits: ["scrollToIntro"],
};
</script>

<style scoped lang="scss">
.container {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  transition: all 0.5s ease-in-out;

  input {
    position: fixed;
    top: 1.8rem;
    left: 1.9rem;
    transform: scale(2.2);
    z-index: 2;
    padding: 2rem;
    opacity: 0;
    cursor: pointer;
  }

  &__large-screen_menu {
    display: none;
    opacity: 0;
    @media screen and (min-width: 40em) {
      display: block;
      opacity: 0;
      animation: appearLargeMenu 0.5s ease-in-out 2s forwards;
      text-shadow: 1px 1px 2px #2c3e50;
    }
  }

  &__menu_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    background: var(--color-tertiary);
    position: fixed;
    width: 4rem;
    height: 3.6rem;
    transition: all 0.5s ease-out;
    border-radius: 0.6rem;

    &__burger {
      position: fixed;
      width: 2.4rem;
      height: 0.4rem;
      background: white;
      border-radius: 0.5rem;
      transition: all 0.5s ease-in-out;

      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 2.4rem;
        height: 0.4rem;
        background: white;
        border-radius: 0.5rem;
        transition: all 0.5s ease-in-out;
      }

      &:before {
        transform: translateY(-0.8rem);
      }

      &:after {
        transform: translateY(0.8rem);
      }

      &_open {
        background: transparent;
        width: 0;
        transform: translateX(0.2rem);

        &:before {
          transform: rotate(45deg) translateX(-1rem) translateY(1rem);
        }

        &:after {
          transform: rotate(-45deg) translateX(-1rem) translateY(-1rem);
        }
      }
    }
  }

  ul {
    position: fixed;
    right: 2rem;
    margin-top: 0;
    list-style: none;
    background: rgba(218, 232, 242, 0.8);
    padding: 2rem 1rem 1rem 1rem;
    border-radius: 0 0 3rem 3rem;

    li {
      padding: 2rem;

      &:hover {
        transform: translateY(-2%) scale(1.01);
        transition: all 0.3s ease;
      }

      a {
        color: white;
        text-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 1);
        padding: 1rem;
      }
    }
  }
}

.collapse-enter-from,
.collapse-leave-to {
  opacity: 0;
  transform: translateY(-14rem);
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.8s ease-in-out;
}
</style>

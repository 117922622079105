<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>


export default {
  name: 'App',
  created() {
    document.title = 'vhodobay'
  }
}
</script>

<style lang="scss">

main {
  overflow: hidden;
  top: 0;
}

</style>

<template>
<div class="container">
</div>
</template>

<script>
export default {
  name: "vShape"
}
</script>

<style scoped lang="scss">
.container {
  background-color: var(--color-four);
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 70%, 85% 0, 100% 0, 50% 100%, 0 0, 15% 0);
}
</style>

<template>
  <span>available from {{ setMonth }}.</span>
</template>

<script>
export default {
  name: "availableComp",
  computed: {
    setMonth() {
      const month = new Date().getMonth() + 3
      const year = new Date().getUTCFullYear()

      let calcMonth
      let calcYear
      if (12 < month) {
        calcMonth = month - 12
        calcYear = year + 1
      } else {
        calcMonth = month
        calcYear = year
      }
      return calcMonth.toString() + '/' + calcYear.toString()
    }
  },

}
</script>

<style scoped>

</style>

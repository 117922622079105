<template>
  <div class="container" id="tile-container">
    <div :class="{ desktop: !mobileWith, mobile: mobileWith }">
      <div
          :style="{ opacity: opacityVal_1 }"
          @click="randomMachine"
          @mouseleave="randomMachine"
          class="desktop__my-tile"
      >
        <transition name="fade" mode="out-in">
          <pattern-box
              :mobile-with="mobileWith"
              key="main"
              v-if="opacityVal_1 < 0.3"
              :random-num="opacityVal_1"
          ></pattern-box>

          <v-shape key="secondary" v-else></v-shape>
        </transition>
      </div>

      <div
          :style="{ opacity: opacityVal_2 }"
          @click="randomMachine"
          @mouseleave="randomMachine"
          class="desktop__my-tile"
      >
        <transition name="fade" mode="out-in">
          <pattern-box
              :mobile-with="mobileWith"
              key="main"
              v-if="opacityVal_2 > 0.3"
              :random-num="opacityVal_2"
          ></pattern-box>

          <v-shape key="secondary" v-else></v-shape>
        </transition>
      </div>
      <div
          :style="{ opacity: opacityVal_3 }"
          @click="randomMachine"
          @mouseleave="randomMachine"
          class="desktop__my-tile"
      >
        <transition name="fade" mode="out-in">
          <pattern-box
              key="main"
              :mobile-with="mobileWith"
              v-if="opacityVal_3 > 0.3"
              :random-num="opacityVal_3"
          ></pattern-box>
          <v-shape key="secondary" v-else></v-shape>
        </transition>
      </div>

      <div
          :style="{ opacity: opacityVal_4 }"
          @click="randomMachine"
          @mouseleave="randomMachine"
          class="desktop__my-tile"
      >
        <transition name="fade" mode="out-in">
          <pattern-box
              :mobile-with="mobileWith"
              key="main"
              v-if="opacityVal_4 > 0.2"
              :random-num="opacityVal_4"
          ></pattern-box>

          <div key="secondary" v-else></div>
        </transition>
      </div>
      <div
          :style="{ opacity: opacityVal_5 }"
          @click="randomMachine"
          @mouseleave="randomMachine"
          class="desktop__my-tile"
      >
        <transition name="fade" mode="out-in">
          <pattern-box
              :mobile-with="mobileWith"
              key="main"
              v-if="opacityVal_5 > 0.5"
              :random-num="opacityVal_5"
          ></pattern-box>

          <v-shape key="secondary" v-else></v-shape>
        </transition>
      </div>
      <div
          :style="{ opacity: opacityVal_6 }"
          @click="randomMachine"
          @mouseleave="randomMachine"
          class="desktop__my-tile"
      >
        <transition name="fade" mode="out-in">
          <pattern-box
              :mobile-with="mobileWith"
              key="main"
              v-if="opacityVal_6 > 0.4"
              :random-num="opacityVal_6"
          ></pattern-box>

          <div key="secondary" v-else></div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import PatternBox from "@/components/ui/shapes/patternBox";
import VShape from "@/components/ui/shapes/vShape";


export default {
  name: "undoTiles",
  props: ["mobileWith"],
  components: {VShape, PatternBox},
  methods: {
    randomMachine() {
      const random = Math.ceil(Math.random() * 6);
      setTimeout(() => {
        this.opacityCalc(random);
      }, random * 200 + 100);
    },
    opacityCalc(num) {
      const randomNum = parseFloat(Math.random().toFixed(2));

      switch (num) {
        case 1:
          this.opacityVal_1 = randomNum;
          break;
        case 2:
          this.opacityVal_2 = randomNum;
          break;
        case 3:
          this.opacityVal_3 = randomNum;
          break;
        case 4:
          this.opacityVal_4 = randomNum;
          break;
        case 5:
          this.opacityVal_5 = randomNum;
          break;
        case 6:
          this.opacityVal_6 = randomNum;
          break;
        default:
          return;
      }
    },
  },
  data() {
    return {
      opacityVal_1: 0,
      opacityVal_2: 0,
      opacityVal_3: 0,
      opacityVal_4: 0,
      opacityVal_5: 0,
      opacityVal_6: 0,
    };
  },
  mounted() {

    const randNum = Math.ceil(Math.random() * 6);
    setTimeout(() => {
      for (let i = 0; i < randNum; i++) {
        this.randomMachine();
      }
    }, 9000);

  },
};
</script>

<style scoped lang="scss">
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.mobile {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.desktop {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  &__my-tile {
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: var(--color-secondary);
  }
}
</style>

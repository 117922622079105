<template>
  <section class="navbar">
    <Navbar
        :white-background="promiseIntersects"
        v-on:scroll-to-intro="scrollToIntroduction"
    />
  </section>

  <section>
    <new-contact v-if="!heroIntersects"></new-contact>
  </section>
  <section v-show="heroInView" id="hero-observe">
    <HeroSection
        :inner-width="innerWidth"
        :mobile-with="mobileWith"
        :inner-height="innerHeight"
        :scroll-to-promise="scrollToPromise"
        :hero-in-view="heroInView"
    />
  </section>
  <section v-if="promiseInView" id="introduction">
    <introduction-comp :inner-with="innerWidth"></introduction-comp>
  </section>
  <section v-show="promiseInView" id="promise-observe">
    <PromiseComp
        :inner-with="innerWidth"
        @scroll-to-persuade="scrollToPersuade"
    />
  </section>

  <section id="persuade-observe" v-show="persuadeInView">
    <PersuadeSection
        :persuade-in-view="persuadeInView"
    />
  </section>
</template>

<script>

import HeroSection from "@/components/hero/heroSection";
import Navbar from "@/components/navbar";

import {defineAsyncComponent} from "vue";


const NewContact = defineAsyncComponent(() => import('@/components/new_contact/newContact'))
const PromiseComp = defineAsyncComponent(() => import('@/components/promise/promiseComp'))
const PersuadeSection = defineAsyncComponent(() => import('@/components/persuade/persuadeSection'))
const IntroductionComp = defineAsyncComponent(() => import("@/components/intro_comp/introductionComp"))
export default {
  name: "Home",
  components: {
    IntroductionComp,
    NewContact,
    PersuadeSection,
    PromiseComp,
    HeroSection,
    Navbar,
  },
  data() {
    return {
      intersectionObserver: null,
      promiseIntersects: false,
      heroIntersects: true,
      elementsReady: false,
      heroInView: true,
      promiseInView: false,
      persuadeInView: false,
      mobileWith: null,
      notYet: true,
    };
  },
  methods: {
    onElementObserved(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.id === "promise-observe") {
            this.promiseIntersects = true;
            this.promiseInView = true;
            this.heroIntersects = false;
            this.intersectionObserver.unobserve(
                document.querySelector("#hero-observe")
            );
          } else if (entry.target.id === "hero-observe") {
            this.heroIntersects = true;
            this.promiseIntersects = false;
          }
        } else {
          this.promiseIntersects = false;
          this.heroIntersects = false;
        }
      });
    },
    scrollToTop() {
      setTimeout(() => {
        let element = document.getElementById("hero-observe");
        element.scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    },
    scrollToPromise() {
      this.promiseIntersects = true;
      this.promiseInView = true;

      setTimeout(() => {
        const element = document.querySelector("#introduction");
        this.scrollerFn(element);
      }, 100);
    },
    scrollToIntroduction() {

      this.promiseInView = true;

      setTimeout(() => {
        const element = document.querySelector("#introduction");
        this.scrollerFn(element);
      }, 100);
    },
    scrollToPersuade() {
      this.persuadeInView = true;
      const element = document.getElementById("persuade-observe");
      this.scrollerFn(element);
    },
    scrollerFn(element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end", inline: "nearest"
        });
      }, 100);
    },
    setInnerWithVal() {
      this.mobileWith = window.innerWidth < 500;
    },
  },
  computed: {
    innerHeight() {
      return window.innerHeight;
    },
    innerWidth() {
      return window.innerWidth;
    },
  },
  mounted() {
    this.setInnerWithVal();
    this.scrollToTop();
    this.intersectionObserver = new IntersectionObserver(
        this.onElementObserved,
        {
          threshold: 0.8,
          rootMargin: "100px",
        }
    );
    this.intersectionObserver.observe(
        document.querySelector("#promise-observe")
    );
    this.intersectionObserver.observe(document.querySelector("#hero-observe"));
  },
  beforeUnmount() {
    this.intersectionObserver.unobserve(
        document.querySelector("#promise-observe")
    );
    this.intersectionObserver.unobserve(
        document.querySelector("#hero-observe")
    );
  },
};
</script>


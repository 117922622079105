<template>
  <div class="container">
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_3),
        clipPath: shapeGenerator(randomCeilNum_1),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_2),
        clipPath: shapeGenerator(randomCeilNum_2),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_2),
        clipPath: shapeGenerator(randomCeilNum_3),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_1),
        clipPath: shapeGenerator(randomCeilNum_3),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_2),
        clipPath: shapeGenerator(randomCeilNum_2),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_3),
        clipPath: shapeGenerator(randomCeilNum_1),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_2),
        clipPath: shapeGenerator(randomCeilNum_1),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_1),
        clipPath: shapeGenerator(randomCeilNum_3),
      }"
    ></div>
    <div
        class="shape-holder"
        :style="{
        backgroundColor: colorGenerator(randomCeilNum_3),
        clipPath: shapeGenerator(randomCeilNum_2),
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "patternBox",
  props: ["randomNum", "mobileWith"],
  data() {
    return {
      path_1: "polygon(0 0, 0% 100%, 100% 100%)",
      path_2: "polygon(0 0, 0% 100%, 100% 0)",
      // path_3: `circle(47% at 50% 50%)`
      color_1: "#262F40",
      color_2: "#6D7B8C",
      color_3: "#DAE8F2"

    };
  },
  computed: {
    path_3() {
      if (this.mobileWith) {
        return `circle(38% at 50% 50%)`
      } else {
        return `circle(43% at 50% 50%)`
      }
    },
    randomCeilNum_1() {
      return Math.ceil(Math.random() * 3);
    },
    randomCeilNum_2() {
      return Math.ceil(this.randomNum * 3);
    },
    randomCeilNum_3() {
      return Math.ceil(Math.random() * 3);
    },
  },
  methods: {
    colorGenerator(num) {
      switch (num) {
        case 1:
          return this.color_1;
        case 2:
          return this.color_2;
        case 3:
          return this.color_3;
      }
    },
    shapeGenerator(num) {
      switch (num) {
        case 1:
          return this.path_1;
        case 2:
          return this.path_2;
        case 3:
          return this.path_3;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.shape-holder {
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}
</style>

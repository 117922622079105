import {createApp} from 'vue'

import {createStore} from 'vuex'
import App from './App.vue'


import Home from "@/views/Home";

import './assets/styles/styles.scss'

import {createRouter, createWebHistory} from "vue-router";


const projectsPage = () => import('@/views/projectsPage')
const xmasPage = () => import('@/views/xmasPage')
const contactMePage = () => import('@/views/contactMePage')
const loginAdmin = () => import('@/views/loginAdmin')
const avatarPlayerMe = ()=>import("@/views/avatarPlayerMe")

const store = createStore({
  state() {
    return {
      level: 0,
      record: 0,

    }
  },
  mutations: {
    increase(state) {
      state.level++
    },
    reset(state) {
      state.level = 0
    },
    setRecord(state, n) {
      state.record = n
    },
    
  }
})

const router = createRouter({
  routes: [
    {path: '/', name: 'Home', component: Home},
    {path: '/projects', component: projectsPage},
    {path: '/contact-me', component: contactMePage},
    {path: '/xmastree', component: xmasPage},
    {path: '/login-admin', component: loginAdmin},
    {path: '/avatar', component: avatarPlayerMe},
  ],
  history: createWebHistory('/')
})


const app = createApp(App)
app.use(store)
app.use(router)

app.mount('#app')

<template>

  <button @click="scrollTo">
    <slot>next</slot>
  </button>

</template>

<script>
export default {
  name: "roundButton",
  props: ['scrollTo']
}
</script>

<style scoped>
button {
  cursor: pointer;
  position: relative;
  z-index: 5;
  background-color: transparent;
  border-radius: 8rem;
  width: 10rem;
  height: 10rem;
  border: dashed 1px white;
  color: white;

}

</style>
